<template>
  <div>
    <v-skeleton-loader
      :loading="initialLoading"
      type="heading, card, table-tbody"
    >
      <v-card-title class="d-flex align-center justify-space-between">
        <h2 class="d-flex align-center">
          <v-btn
            class="mr-4"
            icon
            @click="() => $router.back()"
          >
            <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
          </v-btn>
          Action Details
        </h2>
        <div>
          <v-btn
            v-show="['stoped', 'done'].includes(details.status)"
            icon
            color="primary"
            @click="() => openRetryActionModal(details.id)"
          >
            <v-icon>{{ icons.mdiReplay }}</v-icon>
          </v-btn>
          <v-btn
            v-show="['in_progress', 'pending'].includes(details.status)"
            icon
            color="error"
            @click="() => tryStopAction(details.id)"
          >
            <v-icon>{{ icons.mdiStop }}</v-icon>
          </v-btn>
          <v-btn
            v-show="!loading"
            icon
            @click="() => fetchActionDetails()"
          >
            <v-icon>
              {{ icons.mdiRefresh }}
            </v-icon>
          </v-btn>
          <v-progress-circular
            :value="progress"
            :size="50"
            :rotate="270"
            :indeterminate="loading"
            color="accent"
          >
            <span v-show="!loading">{{ countdown }}</span>
          </v-progress-circular>
        </div>
      </v-card-title>
      <v-layout column>
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col md="6">
                <v-row>
                  <v-col
                    lg="2"
                    md="4"
                  >
                    Type:
                  </v-col>
                  <v-col>
                    {{ details.type }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    lg="2"
                    md="4"
                  >
                    Status:
                  </v-col>
                  <v-col>
                    {{ details.status }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6">
                <v-row>
                  <v-col
                    lg="3"
                    md="5"
                  >
                    Account Delay:
                  </v-col>
                  <v-col>
                    {{ details.account_delay }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    lg="3"
                    md="5"
                  >
                    Batch Delay:
                  </v-col>
                  <v-col>
                    {{ details.batch_delay }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    lg="3"
                    md="5"
                  >
                    Batch Size:
                  </v-col>
                  <v-col>
                    {{ details.batch_size }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>
            Accounts
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              :mobile-breakpoint="0"
              item-key="account_id"
              loading-text="Loading accounts ..."
              :server-items-length="total"
              :loading="loading"
              :items="details.accounts"
              :headers="accountHeaders"
              show-expand
              :options.sync="tableOptions"
              :footer-props="{
                'items-per-page-options': pages,
                'show-first-last-page': true,
                'show-current-page': true,
              }"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  class="pa-4"
                  :colspan="12"
                >
                  <v-row>
                    <v-col md="2">
                      Discord Account Id:
                    </v-col>
                    <v-col> {{ item.discord_user_id }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2">
                      Proxy:
                    </v-col>
                    <v-col>
                      <v-tooltip
                        v-if="item.proxy"
                        right
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <span

                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ item.proxy.name || item.proxy.value }}
                          </span>
                        </template>
                        <span v-show="item.proxy.name">{{ item.proxy.value }}</span>
                      </v-tooltip>
                      <span v-else>
                        no proxy
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2">
                      Login:
                    </v-col>
                    <v-col> {{ item.login }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2">
                      Password:
                    </v-col>
                    <v-col>
                      {{ item.password }}
                      <CopyTextBtn :text="item.password" />
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col md="2">
                      Token:
                    </v-col>
                    <v-col>
                      {{ item.token }}
                      <CopyTextBtn :text="item.token" />
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col md="2">
                      Seller:
                    </v-col>
                    <v-col> {{ item.seller || 'no seller' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2">
                      Error:
                    </v-col>
                    <v-col> {{ item.error.length>0?item.error[0]:"No errors" }}</v-col>
                  </v-row>
                </td>
              </template>
              <template
                v-slot:top
              >
                <v-layout
                  d-flex
                  align-center
                  class="ma-2"
                >
                  <v-btn
                    v-model="showFilters"
                    :color="showFilters ? 'primary': 'default'"
                    icon
                    plain
                    @click="() => showFilters = !showFilters"
                  >
                    <v-icon>{{ icons.mdiFilter }}</v-icon>
                  </v-btn>
                </v-layout>
                <v-layout
                  v-show="showFilters"
                  class="pa-4"
                >
                  <v-row>
                    <v-col cols="3">
                      <v-select
                        v-model="tableOptions.status"
                        label="Status"
                        placeholder="Select status"
                        hide-details
                        clearable
                        :items="actionDetailsStatuses"
                        class="pa-0 ma-0"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-layout>
              </template>
              <template v-slot:item.status="{item}">
                <v-chip
                  :color="StatusesColorMap[item.status].color"
                >
                  {{ StatusesColorMap[item.status].text }}
                </v-chip>
              </template>
              <template v-slot:footer="{ props, on }">
                <TableFooter
                  v-bind="props"
                  v-on="on"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-layout>
    </v-skeleton-loader>

    <RetryActionModal
      ref="retryActionModal"
      @updated="(value) => details = {...details, ...value}"
    />
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheckCircleOutline,
  mdiLockOutline,
  mdiDotsVertical,
  mdiMagnify,
  mdiPencil,
  mdiCancel,
  mdiFilter,
  mdiReplay,
  mdiStop,
  mdiRefresh,
} from '@mdi/js'
import pick from 'lodash/pick'
import { actionDetailsStatuses, StatusesColorMap, projectAccountStatuses } from '@core/constants'
import DiscordApi from '@core/api/discord'
import RetryActionModal from '@/components/discord/action/RetryActionModal.vue'
import TableFooter from '@/components/TableFooter.vue'

export default {
  components: { RetryActionModal, TableFooter },
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDotsVertical,
        mdiChevronLeft,
        mdiCheckCircleOutline,
        mdiLockOutline,
        mdiMagnify,
        mdiCancel,
        mdiFilter,
        mdiReplay,
        mdiStop,
        mdiRefresh,
      },
      initialLoading: true,
      actions: [],
      loading: true,
      details: {},
      total: 50,
      showFilters: false,
      isProgress: true,
      countdown: 60,
      actionDetailsStatuses,
      StatusesColorMap,
      projectAccountStatuses,
      oldStatus: null,
      tableOptions: {
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
        status: this.$route.query.status ? this.$route.query.status : null,
      },
      pages: [50, 100, 200],
      accountHeaders: [
        {
          text: 'Login',
          value: 'login',
          filterable: true,
          sortable: false,
          align: 'start',
        }, {
          text: 'Error',
          value: 'error',
        },
        {
          text: 'Status',
          value: 'status',
          filterable: true,
          sortable: false,
          align: 'center',
        },
      ],
    }
  },
  computed: {
    progress() {
      return this.countdown * 1.6
    },
  },
  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value, oldValue) {
        const query = pick(value, ['page', 'status'])
        query.limit = value.itemsPerPage
        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })
        if (value.status !== this.oldStatus
                    || oldValue?.itemsPerPage !== value.itemsPerPage) {
          this.fetchActionDetails(query)
        }
        this.oldStatus = value.status
        this.$router.replace({ query }).catch(() => { })
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.fetchActionDetails()
    this.initialLoading = false
  },
  methods: {
    async fetchActionDetails(params = this.$route.query) {
      try {
        this.loading = true
        const { data } = await DiscordApi.Action.fetchAction(this.$route.params.id, params)
        // eslint-disable-next-line
        this.total = data.total;
        this.details = data
        this.loading = false
        if (this.isProgress) {
          this.restartRefreshInterval()
        }
      } catch (err) {
        console.err(err)
      }
    },
    restartRefreshInterval() {
      this.countdown = 60
      clearInterval(this.intervalId)
      this.intervalId = setInterval(() => {
        this.countdown -= 1
        if (!this.countdown) {
          clearInterval(this.intervalId)
          this.fetchActionDetails()
        }
      }, 1000)
    },
    async tryStopAction(id) {
      try {
        const { data } = await DiscordApi.Action.stopAction({ id })
        this.details = { ...this.details, ...data }
        this.$message.success('Action stopped')
      } catch (err) {
        this.$message.error(err)
      }
    },
    openRetryActionModal(action) {
      this.$refs.retryActionModal.open(action)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel {
  &-header {
    padding: 0;
    padding-right: 16px;
  }
  &-content__wrap {
    padding: 0;
  }
}

::v-deep .v-skeleton-loader {
  &__heading {
    margin: 1rem 0;
  }

  &__card {
    margin: 1rem 0;
  }

  &__paragraph {
    margin: 1rem 0;
  }
}
</style>
